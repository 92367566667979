export enum Preferences {
  IsNavigationExpanded = 'view:is-navigation-expanded',
  IsSecondaryNavigationExpanded = 'view:is-secondary-navigation-expanded',
  IsDashboardFiltersExpanded = 'view:is-dashboard-filters-expanded',
  IsSupplierSidePanelExpanded = 'view:is-supplier-side-panel-expanded',
  IsQuotationSidePanelExpanded = 'view:is-quotation-side-panel-expanded',

  DateFormat = 'settings:date-format',

  QuotationSourcingScopeColumns = 'view:quotation-sourcing-scope-columns',
  IncidentsColumns = 'view:incidents-columns',
  ContactsColumns = 'view:contacts-columns',
  LegalContractsColumns = 'view:legal-contracts-columns',
  SupplierManagementColumns = 'view:supplier-management-columns',
  CategoryManagementColumns = 'view:category-management-columns',
  TemplateManagementColumns = 'view:template-management-columns',
  SupplierStrategyTaskColumns = 'view:supplier-strategy-task-columns',
  SupplierStrategySourcingEventsColumns = 'view:supplier-strategy-sourcing-events-columns',
  CategoryMonitoringColumns = 'view:category-monitoring-columns',
  StrategyManagementColumns = 'view:strategy-management-columns',
  QuotationsByStatusColumns = 'view:quotations-by-status-columns',
  ResourceIncotermsColumns = 'view:resource-incoterms-columns',
  ResourceTermsAndConditionsColumns = 'view:resource-terms-and-conditions-columns',
  LocalDivisionUsersColumns = 'view:local-division-users-columns',
  ResourcePaymentTermsColumns = 'view:resource-payment-terms-columns',
  ResourceCurrenciesColumns = 'view:resource-currencies-columns',
  ResourceVariablesColumns = 'view:resource-variables-columns',
  ResourceExchangeRatesColumns = 'view:resource-exchange-rates-columns',
  OrganisationManagersColumns = 'view:organisation-managers-columns',
  ResourceMdfCodesColumns = 'view:resource-mdf-codes-columns',
  UsersColumns = 'view:users-columns',
  ResourceLinksColumns = 'view:resource-links-columns',
  ResourceDocumentsColumns = 'view:resource-documents-columns',
  HeatmapColumns = 'view:heatmap-columns',
  HeatmapProjectsColumns = 'view:heatmap-projects-columns',
  SPESupplierSelectionColumns = 'view:spe-supplier-selection-columns',
  SPEEvaluatorsSelectionColumns = 'view:spe-evaluators-selection-columns',
  SupplierPerformanceEvaluationsColumns = 'view:supplier-performance-evaluations-columns',

  QuotationRevisionTrackerInformationFields = 'view:quotation-revision-tracker-information-fields',
  QuotationRevisionTrackerTechnicalRequirementsFields = 'view:quotation-revision-tracker-technical-requirements-fields',
  QuotationRevisionTrackerCommercialRequirementsFields = 'view:quotation-revision-tracker-commercial-requirements-fields',
  QuotationRevisionTrackerLogisticalRequirementsFields = 'view:quotation-revision-tracker-logistical-requirements-fields',
  QuotationRevisionTrackerTCOFields = 'view:quotation-revision-tracker-tco-fields',

  UseNewFilters = 'feature:use-new-filters',

  FavoriteOrder = 'view:favorite-order',
  SourcingOrder = 'view:sourcing-order',
  SourcingScopeOrder = 'view:sourcing-scope-order',
  SuppliersOrder = 'view:suppliers-order',
  CategoriesOrder = 'view:categories-order',
  TemplatesOrder = 'view:templates-order',
  SupplierStrategySourcingEventsOrder = 'view:supplier-strategy-sourcing-events-order',
  CategoryMonitoringOrder = 'view:category-monitoring-order',
  ExternalContactsOrder = 'view:external-contacts-order',
  LegalContractsOrder = 'view:legal-contracts-order',
  StrategyManagementOrder = 'view:strategy-management-order',
  LocalDivisionUsersOrder = 'view:local-division-users-order',
  OrganisationManagersOrder = 'view:organisation-managers-order',
  DivisionsReviewOrder = 'view:divisions-review-order',
  HubsReviewOrder = 'view:hubs-review-order',
  LocalDivisionsReviewOrder = 'view:local-divisions-review-order',
  LocalDivisionAssignmentsOrder = 'view:local-division-assignments-order',
  UsersOrder = 'view:users-order',
  VariablesOrder = 'view:variables-order',
  IncotermsOrder = 'view:incoterms-order',
  TermsAndConditionsOrder = 'view:terms-and-conditions-order',
  CurrenciesOrder = 'view:currencies-order',
  ExchangeRatesOrder = 'view:exchange-rates-order',
  PaymentTermsOrder = 'view:payment-terms-order',
  ResourceLinksOrder = 'view:resource-links-order',
  ResourceDocumentsOrder = 'view:resource-documents-order',
  HeatmapOrder = 'view:heatmap-order',
  IncidentsOrder = 'view:incidents-order',
  SPESupplierSelectionOrder = 'view:spe-supplier-selection-order',
  SPEEvaluatorsSelectionOrder = 'view:spe-evaluators-selection-order',
  SupplierPerformanceEvaluationsOrder = 'view:supplier-performance-evaluations-order',
  UserSurveyEvaluationsOrder = 'view:user-survey-evaluations-order',
  SurveyEvaluationsOrder = 'view:survey-evaluations-order',
}
