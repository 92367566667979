import { EntityIdAliasPrefixes } from '@abb-procure/constants';
import { BooleanInput } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  booleanAttribute,
  computed,
  inject,
  input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardService } from '../../services/clipboard.service';
import { EntityUtility } from '../../utilities/entity.util';
import { BadgeComponent } from '../badge/badge.component';

const colors: Record<EntityIdAliasPrefixes, string> = {
  PROJ: '#ff6c25',
  RFRFQ: '#00acb6',
  RFQ: '#0096ea',
  QUOT: '#6d1541',
  PREQ: '#eece23',
  PEX: '#d90000',
  PE: '#0a8a15',
  TRFRFQ: '#00667a',
  CATM: '#000000',
  SUP: '#991f00',
  STRAT: 'transparent',
  USER: 'transparent',
  TSPE: '#190482',
  PSPE: '#6D67E4',
  GSPT: '#ff000f',
  SPE: '#b266ff',
};

@Component({
  selector: 'procure-entity-badge',
  templateUrl: './entity-badge.component.html',
  styleUrls: ['./entity-badge.component.scss'],
  standalone: true,
  imports: [BadgeComponent, MatTooltipModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityBadgeComponent {
  @HostBinding('class')
  @Input()
  hostClass: string = '';

  label = input.required<string | null>();
  tooltip = input<string | null>('');

  clickable = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  linked = input<boolean, BooleanInput>(true, {
    transform: booleanAttribute,
  });

  copyNumber = input<boolean, BooleanInput>(true, {
    transform: booleanAttribute,
  });

  condensed = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  openInNewTab = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  protected readonly type = computed(() => {
    const label = this.label();

    if (!label) {
      return EntityIdAliasPrefixes.Project;
    }

    return EntityUtility.getType(label);
  });

  protected readonly backgroundColor = computed(() => {
    const type = this.type();
    return type ? colors[type] : null;
  });

  private readonly clipboardService = inject(ClipboardService);

  protected copyLink(): void {
    if (!this.linked()) {
      return;
    }

    this.clipboardService.copy(`${window.location.origin}/${this.label()}`);
  }

  protected copyEntityNumber(): void {
    if (!this.copyNumber()) {
      return;
    }

    this.clipboardService.copy(`[${this.label()}]`);
  }
}
