import { WINDOW } from '@abb-procure/constants';
import { Injectable, Signal, inject, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChildViewNavigationService {
  get activeView(): Signal<string> {
    return this.state.asReadonly();
  }

  private state = signal('');
  private scrollPositions: Record<string, number> = {};
  private readonly window: Window = inject(WINDOW);

  setActiveView(view: string): void {
    this.state.set(view);
    this.window.scrollTo(0, this.scrollPositions[view] || 0);
  }
}
